import { combineComponents } from "@utils/combineComponents";
import { AuthProvider } from "./AuthContext";
import { CompanyProvider } from "./CompanyContext";
import { SubscriptionProvider } from "./SubscriptionContext";
import { ClientProvider } from "./ClientContext";
import { AccountingSoftwareProvider } from "./AccountingSoftwareContext";

const providers = [
  AuthProvider,
  CompanyProvider,
  ClientProvider,
  SubscriptionProvider,
  AccountingSoftwareProvider,
];

export const AppContextProvider = combineComponents(...providers);
