import "../styles/index.css";
import "../styles/layout.css";
import "../styles/light-theme.css";
import "react-quill/dist/quill.snow.css";

import { AppContextProvider } from "@context/AppContextProvider";

import type { AppProps } from "next/app";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <AppContextProvider>
      <Component {...pageProps} />
    </AppContextProvider>
  );
}
export default MyApp;
