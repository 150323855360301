import { createContext, useState, useEffect, useContext } from "react";
import { notification } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import { useRouter } from "next/router";

import AuthContext from "./AuthContext";
import { API_URL } from "@config";
import { SubscriptionStats } from "@models/subscription_status.model";
import ClientContext from "./ClientContext";
import { SubscriptionPlan } from "@models/subscription_plan";

type SubscriptionContext = {
  stats: SubscriptionStats | undefined;
  subscribe: (subscriptionPlanId: string) => Promise<void>;
  verify: (companyId: string, planId: string) => Promise<void>;
  getSubscriptionPlans: () => Promise<SubscriptionPlan[]>;
  cancelSubscription: () => Promise<void>;
  loadData: () => Promise<void>;
  loading: boolean;
};

const SubscriptionContext = createContext<SubscriptionContext>({} as any);

export const SubscriptionProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState<SubscriptionStats>();
  const { user } = useContext(AuthContext);
  const { clients } = useContext(ClientContext);
  const router = useRouter();

  useEffect(() => {
    if (user) loadData();
  }, [user, clients]);

  const loadData = async () => {
    const token = Cookies.get("token");
    if (!token) throw new Error();

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const { data } = await axios.get<SubscriptionStats>(
        `${API_URL}/subscriptions/stats`,
        {
          headers,
        }
      );
      setStats(data);
    } catch (error: any) {
      notification.error({ message: error.message });
    }

    setLoading(false);
  };

  const verify = async (companyId: string, planId: string): Promise<void> => {
    const token = Cookies.get("token");
    if (!token) throw new Error();

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    await axios.post(
      `${API_URL}/subscriptions/verify?company_id=${companyId}&plan_id=${planId}`,
      null,
      { headers }
    );
  };

  const subscribe = async (subscriptionPlanId: string) => {
    const token = Cookies.get("token");
    if (!token) throw new Error();

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const { data } = await axios.post<string>(
        `${API_URL}/subscriptions`,
        {
          plan_id: subscriptionPlanId,
        },
        { headers }
      );

      router.replace(data);
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };

  const getSubscriptionPlans = async (): Promise<SubscriptionPlan[]> => {
    const token = Cookies.get("token");
    if (!token) throw new Error();

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const { data } = await axios.get<SubscriptionPlan[]>(
        `${API_URL}/subscription-plans`,
        { headers }
      );
      return data;
    } catch (error) {
      // FIXME log
      throw error;
    }
  };

  const cancelSubscription = async (): Promise<void> => {
    const token = Cookies.get("token");
    if (!token) throw new Error();

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await axios.delete(
        `${API_URL}/subscriptions`,
        { headers }
      );
    } catch (error) {

    }

  }

  return (
    <SubscriptionContext.Provider
      value={{
        stats,
        loading,
        subscribe,
        verify,
        getSubscriptionPlans,
        cancelSubscription,
        loadData
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionContext;
