import { createContext } from "react";
import { notification } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import { API_URL } from "@config";

type AccountingSoftwareContext = {
  getStatus: (platform: string) => Promise<any>;
  refreshData: (platform: string) => Promise<void>;
  disconnect: (platform: string) => Promise<void>;
};

const AccountingSoftwareContext = createContext<AccountingSoftwareContext>(
  {} as any
);

export const AccountingSoftwareProvider: React.FC = ({ children }) => {
  const getStatus = async (platform: string) => {
    const token = Cookies.get("token");
    if (!token) return;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const resp = await axios.get(
        `${API_URL}/accounting-software/${platform}/status`,
        { headers }
      );

      return resp.data;
    } catch (error: any) {}
  };

  const refreshData = async (platform: string) => {
    const token = Cookies.get("token");
    if (!token) return;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await axios.post(
        `${API_URL}/accounting-software/${platform}/refresh`,
        null,
        { headers }
      );
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };

  const disconnect = async (platform: string) => {
    const token = Cookies.get("token");
    if (!token) return;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      await axios.delete(
        `${API_URL}/accounting-software/${platform}/disconnect`,
        { headers }
      );
    } catch (error: any) {
      notification.error({ message: error.message });
    }
  };

  return (
    <AccountingSoftwareContext.Provider
      value={{ getStatus, refreshData, disconnect }}
    >
      {children}
    </AccountingSoftwareContext.Provider>
  );
};

export default AccountingSoftwareContext;
